.mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  text-align: center;
  padding: 120px 20px;

  div.loading-indicator-fading-circle {
    width: 40px;
    height: 40px;
  }
}
