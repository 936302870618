@import '../styles/popup';

.searchSource--container {
    color: #000;
    text-align: left;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .searchSource--noResults {
    display: none;
  }

  h2 {
    display: inline-block;
    margin: 10px 10px 5px;
    opacity: .8;
    font-weight: normal;
    font-size: 14px;
  }

  ul:not(:empty) {
    li {
      padding: 10px 15px;
      cursor: pointer;
      border-bottom: 1px solid transparentize($gray-lighter, .5);

      .icon {
        width: 16px;
        height: 16px;
      }

      h3 {
        font-size: 14px;
        margin: 0;
      }

      .searchSource--content {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 0;
      }

      .searchSource--provider {
        margin: 0;
        float: right;
        font-style: italic;
        opacity: .9;
        font-size: 11px;
      }

      &:hover {
        background: rgba(0,0,0,.1);
      }
    }
  }
}

@media (min-width: 769px) {
  .searchSource--container {
    .inputWrap {
      & > input { width: 300px; }
    }
  }
}


