@import '../styles/variables/theme';

.comments--comment {
  padding: 15px;
  border-bottom: 1px solid $gray-lighter;

  .comments--author {
    color: $brand-primary;
    font-size: 16px;
    margin: 0;
    display: inline-block;

    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    float: left;
  }

  .comments--date {
    display: inline-block;
    margin: 0 0 10px 5px;
    float: left;
  }

  .comments--commentBody {
    margin: 0;
  }

  .comments--flagWrap {
    float: right;
  }

  .comments--flag {
    cursor: pointer;
  }
}

.comments--pagingToolbar {
  display: block;
  text-align: center;

  padding-bottom: 5px;

  button { vertical-align: inherit; }
  .comments--pageText {
    display: inline-block;
    margin: 0;
    padding: 9px 0;

  }
}
