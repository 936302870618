.article--vimeo {
  position: relative;
  width: 100%;
  height: 0;
}

.article--vimeo iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
