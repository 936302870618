@import '../styles/variables/theme';
@import '../styles/popup';

.article-comments--window {
  position: relative;
  width: 100%;

  .article-comments--form {
    position: relative;
    padding: 15px;

    button {
      float: right;
    }
    a {
      padding: 10px 0;
    }
    .article--coments--captcha {
      margin-bottom: 5px;
    }
    textarea {
      height: 38px;
    }
    input,
    button,
    .article-comments--captcha,
    a {
      display: none;
    }
    input,
    textarea {
      margin-bottom: 15px;
      border-radius: 0;
      height: auto;
    }

    .article-comments--commentBody {
      display: inline;
    }

    & > textarea,
    &.article-comments--expanded {
      textarea {
        height: auto;
      }
      input,
      button,
      .article-comments--captcha,
      a {
        display: block;
      }

      .article-comments--captcha {
        margin-bottom: 5px;
      }
    }
  }
}
