.articles--container {
  background: #fff;
}
.articles--selector {
  margin-bottom: 10px;

  .dropdown {
    width: 100%;
  }

  button {
    white-space: normal;
    text-align: left;
    width: 100%;

    span:last-child {
      float: right;
      margin: 8px 0 3px 2px;
    }
  }
}
