div.article-window {
  position: relative;
  width: 100%;
  min-height: 100px;

  .buttonBar {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .articleProvider {
    margin: 3px 0;
  }

  .article {
    padding: 15px;
    max-height: 100%;

    img {
      max-width: 100%;
      max-height: 500px;
      margin: 0 auto;
    }
  }

  .content {
    margin-top: 10px;

    h1, h2, h3, h4, h5, h6 { font-weight: bold }
    h1 { font-size: 16px }
    h2 { font-size: 14px }
    h3 { font-size: 13px }
    h4 { font-size: 12px }
    h5 { font-size: 11px }
    h6 { font-size: 10px }
  }

  .loadOverlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 20px;
    width: 100%;
    text-align: center;

    .loading-indicator-fading-circle {
      width: 40px;
      height: 40px;
    }
  }

  .error {
    padding: 40px 20px;
    text-align: center;
    color: #900;
  }

  .additionalColumns {
    margin-top: 10px;
  }

  .additionalColumn {
    padding: 5px 0 5px 0;
  }

  .additionalColumnLabel {
    width: 30%;
    font-weight: bold;
  }

  .additionalColumnContent {
    margin-left: 10px;
    word-wrap: break-word;
  }

  .text_area,
  .rick_text {
    display: block;
    margin: 0;
  }
}
@media (min-width: 1400px) {
  div.window {
    position: absolute;
    z-index: 10000;
    height: auto;
    max-height: 100%;
    right: 0;
    width: 440px;
    overflow-y: auto;
  }
}
