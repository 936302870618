@import "../styles/popup";

div.search--container {
  z-index: 10000;
  .search--inputWrap {
    display: table;
    width: 100%;

    & > input {
      width: 100%;
      height: 40px;
      float: left;
      padding-right: 40px;
    }

    & > button {
      float: right;
      width: 40px;
      height: 40px;
      margin-top: -40px;
    }
  }

  .search--loadingElem {
    position: absolute;
    top: 10px;
    right: 40px;
    z-index: 1000;
    opacity: 0.5;
    height: 20px;
    width: 20px;
  }

  .search--searchSources:not(:empty) {
    @include popup;
    max-height: 80vh;
    overflow: auto;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
    border: 1px solid $gray-lighter;
    pointer-events: all;
  }

  .search--hideSources {
    display: none;
  }

  @media (min-width: 769px) {
    .search--searchResults {
      position: inherit;
      width: 300px;
      top: 49px;
      bottom: 10px;
      overflow: auto;
      pointer-events: none;
    }

    .search--searchSources {
      max-height: none;
    }
  }
}

@media (min-width: 769px) {
  .search--container {
    .search--inputWrap {
      & > input {
        width: 300px;
      }
    }
  }
}
