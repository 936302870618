@import '../styles/variables/theme';

.articleList--list .articleList--thumbnail.articleList--withThumbnail {
  position: relative;
  min-height: 212px;

  > img { width: 100% }

  .caption {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    margin: 5px;

    * {
      color: white;
    }
  }
}

.articleList--list {
  position: relative;
}

.articleList--entry {
  border: 1px solid #ccc;
  margin: 0 0 10px;
  padding: 5px;

  .caption {
    padding: 10px;
  }

  &:hover {
    border-color: $brand-primary;
  }
}

.articleList--loading {
  min-height: 280px;

  .articleList--loadOverlay { display: block }
}

.articleList--loadOverlay {
  display: none;
  position: absolute;
  top: 0; bottom: 0;
  left: 0; right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, .5);
  text-align: center;
  padding: 120px 20px;

  .loading-indicator-fading-circle {
    width: 40px;
    height: 40px;
  }

  span {
    margin-right: 5px
  }
}

.articleList--noResults {
  padding: 120px 20px;
  text-align: center;
}

.articleList--content {
  max-height: 92px;
  overflow: hidden;
  text-overflow: ellipsis;

  p { margin: 0 }
  :first-child { padding-top: 0; margin-top: 0; }
  :empty { display: none }

  * {
    font-size: 13px !important;
  }
  h1, h2, h3, h4, h5, h6 { font-weight: bold; }
}
